<template>
  <div>
    <table v-if="r">
      <tr 
        v-for="(raux, index) of r"
        :key="`r${index}`"
      >
        <td
          v-for="(item, index) of raux"
          :key="`c${index}`"
          style="background-color: black;color:white;font-weight:700;padding: 0 5px;width:20px;text-align:center"
        >
          {{item}}
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    size: {
      type: Number,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    r: null,
  }),
  watch: {
    size () {
      this.prepareData()
    },
    value () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      this.r = null
      if (!this.value || !this.size) return
      const aux = this.value.toUpperCase().split('')
      this.r = []
      let i = 0
      let raux = []
      for (const item of aux) {
        if (i === this.size || i === 0) {
          this.r.push(raux)
          raux = []
          i = 0
        }
        
        raux.push(item)
        i++
      }
      if (raux.length > 0) this.r.push(raux)
    },
  },
}
</script>

