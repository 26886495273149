var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"elevation":"0"}},[_c('v-icon',{staticStyle:{"margin-right":"10px"},attrs:{"large":""}},[_vm._v(_vm._s(_vm.icon))]),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.title)+" ")])],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):(_vm.editedItem)?_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',_vm._b({attrs:{"label":_vm.$t('content.name', _vm.locale),"hide-details":""},model:{value:(_vm.editedItem.Name),callback:function ($$v) {_vm.$set(_vm.editedItem, "Name", $$v)},expression:"editedItem.Name"}},'v-text-field',{ 'outlined' : true},false))],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',_vm._b({attrs:{"label":_vm.$t('content.url', _vm.locale),"hide-details":""},model:{value:(_vm.editedItem.URL),callback:function ($$v) {_vm.$set(_vm.editedItem, "URL", $$v)},expression:"editedItem.URL"}},'v-text-field',{ 'outlined' : true},false))],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('custom-label',{attrs:{"label":_vm.$t('content.time', _vm.locale).concat(':')}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('interval-time',{staticStyle:{"width":"100px"},model:{value:(_vm.editedItem.Time),callback:function ($$v) {_vm.$set(_vm.editedItem, "Time", $$v)},expression:"editedItem.Time"}})],1)],1),_c('v-row',{staticStyle:{"padding":"10px"}},[_c('v-select',{attrs:{"itemValue":"id","itemText":"name","dense":"","label":"Estado","hide-details":"","items":[{
          id: 0,
          name: 'Visible'
        },
        {
          id: 1,
          name: 'Oculto'
        }],"outlined":""},model:{value:(_vm.editedItem.Hide),callback:function ($$v) {_vm.$set(_vm.editedItem, "Hide", $$v)},expression:"editedItem.Hide"}})],1),_c('v-row',[_c('availability',{attrs:{"locale":_vm.locale},on:{"change":_vm.handleChangeAvailability},model:{value:(_vm.editedItem.Availability),callback:function ($$v) {_vm.$set(_vm.editedItem, "Availability", $$v)},expression:"editedItem.Availability"}})],1)],1):_vm._e(),(_vm.editedItem)?_c('action-buttons',{attrs:{"id":_vm.editedItem.ID,"onDeleteItem":_vm.handleDelete,"onSaveItem":_vm.handleSave,"onClose":_vm.handleClose,"locale":_vm.locale}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }