<template>
  <div>
    <v-toolbar
      elevation="0"     
    >
    <v-icon large style="margin-right:10px;">{{ icon }}</v-icon>
      <v-toolbar-title>
        {{ isNew ? $t('common.create', locale) : isClone ? $t('common.clone', locale) : $t('common.edit', locale) }} {{ title.toLowerCase() }}
      </v-toolbar-title>
    </v-toolbar>
    <v-progress-linear indeterminate v-if="loading" />
    <v-container v-else-if="editedItem">
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-text-field
            v-bind="{ 'outlined' : true}"
            v-model="editedItem.Name"
            :label="$t('content.name', locale)"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-textarea
            v-bind="{ 'outlined' : true}"
            v-model="editedItem.Message"
            :label="$t('content.message', locale)"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row style="padding: 10px;">
        <v-select
          v-model="editedItem.Hide"
          itemValue="id"
          itemText="name"
          dense
          label="Estado"
          hide-details
          :items="[{
            id: 0,
            name: 'Visible'
          },
          {
            id: 1,
            name: 'Oculto'
          }]"
          outlined
        />
      </v-row>
      <v-row>
        <availability
          v-model="editedItem.Availability"
          :locale="locale"
          @change="handleChangeAvailability"
        />
      </v-row>
    </v-container>
    <action-buttons
      v-if="editedItem"
      :id="editedItem.ID"
      :onDeleteItem="handleDelete"
      :onSaveItem="handleSave"
      :onClose="handleClose"
      :locale="locale"
    />
  </div>
</template>
<script>
  const defaultItem = {
    Name: '',
    Message: '',
    IsTemp: false,
    Availability: null,
  }
  
  import { mapState } from 'vuex'
  import api from '@/services/api'
  import ActionButtons from '@/components/ActionButtons'
  import Availability from '@/components/Availability'

  export default{
    name: 'MessageForm',
    components: {
      ActionButtons,
      Availability,
    },
    props: {
      title: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        required: true
      },
      contentTypeDataID: {
        type: String,
        required: true
      },
      editedID: {
        type: String,
        default: null
      },
      onClose: {
        type: Function,
        required: true
      },
      onDelete: {
        type: Function,
        required: true
      },      
      isClone: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        loading: false,
        editedItem: null
      }
    },
    computed: {
      ...mapState('app',['locale']),
      isNew () {
        return this.editedID ? false : true
      },
    },
    watch: {
      editedID () {
        this.handleGetData()
      },
    },
    mounted () {
      this.handleGetData()
    },
    methods: {
      handleGetData () {
        this.loading = true
        if (!this.editedID) {
          this.editedItem = JSON.parse(JSON.stringify(defaultItem))
          this.editedItem.ContentTypeDataID = this.contentTypeDataID
          this.loading = false
        } else {
          api.getItem ('dviewcontent', `private/messages/${this.contentTypeDataID}/`, this.editedID)
          .then(response => {   
            if (response) response.Hide = response.Hide ? 1 : 0
            this.editedItem = response
            this.loading = false
          })
        }
      },
      handleSave () {
        if (this.editedItem.ID)
          api.updateItem ('dviewcontent', `private/messages/${this.contentTypeDataID}/`, this.editedItem.ID.concat(this.isClone ? '/clone' : ''), this.editedItem)
            .then(() => {   
              this.onClose()
            })
        else 
          api.addItem ('dviewcontent', `private/messages/${this.contentTypeDataID}`, this.editedItem)
            .then(() => {   
              this.onClose()
            })
      },
      handleDelete () {
        this.onDelete(this.editedID)
      },
      handleClose () {
        this.onClose()
      },
      handleChangeAvailability (v) {
        this.editedItem.Availability = v
      },
    }
  }
</script>
